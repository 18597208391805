$(function () {

    $(document).on('click', '.city-toggle', function (e) {
        $(this).closest('.city-box').toggleClass('is-active');
    });
    $(document).on('click', '.cities-list a', function (e) {
        e.preventDefault();
        let city = $(this).text();
        $('.city-box').removeClass('is-active').find('.city-toggle .text').text(city);

    });

    $(document).on('click', '.search-block__btn-toggle', function (e) {
        e.preventDefault();
        let form = $(this).siblings('.search-block__form');
       if ( !form.hasClass('is-active') ) {
           form.addClass('is-active');
           setTimeout(function () {
               form.find('input').focus();
           }, 300);
       } else {
           form.removeClass('is-active');
       }

    });

    $(document).on('click', '.lang-toggle .text', function (e) {
        $(this).closest('.lang-toggle').toggleClass('is-active');
    });
    $(document).on('click', function (e) {
        if (!$(e.target).closest('.lang-toggle').length) {
            $('.lang-toggle').removeClass('is-active');
        }
        if (!$(e.target).closest('.city-box').length) {
            $('.city-box').removeClass('is-active');
        }
        if (!$(e.target).closest('.search-block').length) {
            $('.search-block__form').removeClass('is-active');
        }
        if (!$(e.target).closest('.hamburger-mob-box, .hamburger').length && !$(e.target).hasClass('hamburger')) {
            $('.hamburger-mob-box, .hamburger').removeClass('is-active');
        }
        if (!$(e.target).closest('.header__tools, .user-tools-link').length && !$(e.target).hasClass('user-tools-link')) {
            $('.header__tools').removeClass('is-active');
        }
    });

    $('.user-tools-link').on('click', function (e) {
        e.preventDefault();
        $('.header__tools').toggleClass('is-active');
    });

    $('.hamburger').on('click', function (e) {
        e.preventDefault();
        $(this).toggleClass('is-active');

        if ($(this).hasClass('hamburger-nav')) {
            $('.header-nav-drop').toggleClass('is-active');
        }
        if ($(this).hasClass('hamburger-mob')) {
            $('.hamburger-mob-box').toggleClass('is-active').css('max-height', window.innerHeight - $('.header').outerHeight());
        }
    });


    //scroll
    let header = $('.header');
    let headerH = header.outerHeight();
    function headerScroll() {
        if ($(window).scrollTop() > 0 && window.innerWidth < 1200) {
            header.addClass('scrolled');
            $('.wrapper').css('padding-top', headerH);
        } else {
            header.removeClass('scrolled');
            $('.wrapper').css('padding-top', '');
        }
    }
    headerScroll();
    $(document).on('scroll', function (e) {
        headerScroll();
    });


    //rebuild
    let rebuildFlag1199 = false;
    let rebuildFlag991 = false;
    let rebuildFlag767 = false;
    function rebuildHeader() {
        let search = $('.header__search');
        let logo = $('.header-top .logo');
        let toolsMob = $('.header__tools-mob');
        let tools = $('.header__tools');
        let lang = $('.lang-toggle');
        let city = $('.header__city');
        let headerBot = $('.header-bot');
        let navAdditional = $('.nav-additional');
        let navMain = $('.nav-main');
        let navAdditionalLink = $('.nav-additional__link');
        let navMainLink = $('.nav-main__link');
        let navDrop = $('.header-nav-drop');
        let navMob = $('.header-nav-mob');
        let exRate = $('.header__exchange-rates');
        let hambMobBox = $('.hamburger-mob-box');

        if (window.innerWidth <= 1199 && !rebuildFlag1199) {
            search.prependTo(toolsMob);

            navMainLink.prependTo(navMob);
            navAdditionalLink.appendTo(navMob);

            toolsMob.before(lang);
            rebuildFlag1199 = true;
        }
        if (window.innerWidth <= 991 && !rebuildFlag991) {
            exRate.prependTo(hambMobBox);
            rebuildFlag991 = true;
        }
        if (window.innerWidth <= 767 && !rebuildFlag767) {
            city.prependTo(hambMobBox);
            lang.prependTo(hambMobBox);
            rebuildFlag767 = true;
        }

        if (window.innerWidth > 767 && rebuildFlag767) {
            tools.before(city);
            tools.before(lang);
            rebuildFlag767 = false;
        }
        if (window.innerWidth > 991 && rebuildFlag991) {
            logo.after(exRate);
            rebuildFlag991 = false;
        }
        if (window.innerWidth > 1199 && rebuildFlag1199) {
            logo.after(search);
            navMainLink.prependTo(navMain);
            navAdditionalLink.appendTo(navAdditional);
            navMain.after(lang);
            rebuildFlag1199 = false;
        }
    }
    rebuildHeader();

    $(window).on('resize', function() {
        debounce(rebuildHeader(), 1000/60*10);
        $('.hamburger-mob-box').css('max-height', window.innerHeight - $('.header').outerHeight());
    });

});