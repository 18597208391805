$(function() {

    $(document).on("click", ".footer__search", function(e) {
        e.preventDefault();
        let form = $(".search-block__form");
        if ( !form.hasClass('is-active') ) {
            setTimeout(function() {
                form.addClass('is-active');
            }, 0);
            setTimeout(function () {
                form.find('input').focus();
            }, 300);
        } else {
            form.removeClass('is-active');
        }
    });
    $(".footer__user").on("click", function(e) {
        e.preventDefault();
        setTimeout(function() {
            $('.header__tools').toggleClass('is-active');
        }, 0);

    });

});