const arrowRight = `<div class="default-arrow default-arrow--next"><svg xmlns="http://www.w3.org/2000/svg" width="9.91" height="9" viewBox="0 0 9.91 9">
  <path d="M1243.86,5245.17l-4.1-4.03a0.476,0.476,0,0,0-.34-0.14,0.494,0.494,0,0,0-.34.14,0.489,0.489,0,0,0-.14.33v2.14h-4.36a0.439,0.439,0,0,0-.34.14,0.427,0.427,0,0,0-.15.33v2.84a0.427,0.427,0,0,0,.15.33,0.439,0.439,0,0,0,.34.14h4.36v2.13a0.478,0.478,0,0,0,.48.48,0.476,0.476,0,0,0,.34-0.14l4.1-4.03A0.459,0.459,0,0,0,1243.86,5245.17Z" transform="translate(-1234.09 -5241)"/>
</svg></div>`;
const arrowLeft = `<div class="default-arrow default-arrow--prev"><svg xmlns="http://www.w3.org/2000/svg" width="9.91" height="9" viewBox="0 0 9.91 9">
  <path d="M1097.14,5245.17l4.1-4.03a0.476,0.476,0,0,1,.34-0.14,0.494,0.494,0,0,1,.34.14,0.489,0.489,0,0,1,.14.33v2.14h4.36a0.439,0.439,0,0,1,.34.14,0.427,0.427,0,0,1,.15.33v2.84a0.427,0.427,0,0,1-.15.33,0.439,0.439,0,0,1-.34.14h-4.36v2.13a0.478,0.478,0,0,1-.48.48,0.476,0.476,0,0,1-.34-0.14l-4.1-4.03A0.459,0.459,0,0,1,1097.14,5245.17Z" transform="translate(-1097 -5241)"/>
</svg></div>`;

function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function getScrollbarWidth() {
    var outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.width = "100px";
    outer.style.msOverflowStyle = "scrollbar";

    document.body.appendChild(outer);

    var widthNoScroll = outer.offsetWidth;
    // force scrollbars
    outer.style.overflow = "scroll";

    // add innerdiv
    var inner = document.createElement("div");
    inner.style.width = "100%";
    outer.appendChild(inner);

    var widthWithScroll = inner.offsetWidth;

    // remove divs
    outer.parentNode.removeChild(outer);
    return widthNoScroll - widthWithScroll;
}

// проверка на боковой скролл
function hasScrollbar() {
    // console.log(document.body.scrollHeight, document.body.clientHeight);
    // return document.body.scrollHeight > document.body.clientHeight;
    // console.log($(document).height() > $(window).height());
    return $(document).height() > $(window).height();
}

function number_format(number, decimals, dec_point, thousands_sep) {
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + (Math.round(n * k) / k)
                .toFixed(prec);
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
        .split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '')
        .length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1)
            .join('0');
    }
    return s.join(dec);
}

var mac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i) ? true : false;

function modalOpenMac() {
    // console.log("open", mac);
    if ( mac ) {
        if ($(document).height() > $(window).height() && !$("html").hasClass("apple-fix")) {
            var scrollTop = ($('html').scrollTop()) ? $('html').scrollTop() : $('body').scrollTop(); // Works for Chrome, Firefox, IE...
            $('html').css('top',-scrollTop);
            document.documentElement.classList.add("apple-fix");
        }
    }
}

function modalCloseMac() {
    // console.log("close", mac);
    if (mac) {
        var scrollTop = parseInt($('html').css('top'));
        document.documentElement.classList.remove("apple-fix");
        $('html,body').scrollTop(-scrollTop);
    }
}

class SlickUnslick {
    constructor(slider, options, breakpoint) {
        this.options = options;
        this.slider = slider;
        this.breakpoint = breakpoint;

        this.init();

        const resSl = debounce(() => {
            if ($(window).width() <= this.breakpoint && !this.slider.hasClass('slick-initialized')) {
                this.init();
            }
        }, 160);

        $(window).on('resize', function () {
            resSl();
        });
    };

    init() {
        this.slider.slick(this.options);
    };
}

$(function () {

    //select
    $('.select-toggle').on('click', function (e) {
        e.preventDefault();
        let select = $(this).closest('.select');
        if (select.hasClass('is-active')) {
            $('.select').removeClass('is-active');
        } else {
            $('.select').removeClass('is-active');
            $(this).closest('.select').addClass('is-active');
        }
    });

    $('.select-list a').on('click', function (e) {
        e.preventDefault();
        let val = $(this).data('value');
        let text = $(this).text();
        let select = $(this).closest('.select');

        select.removeClass('is-active');
        select.find('.select-toggle input').val(val);
        select.find('.select-toggle .select-val').text(text).attr('title', text);
    });

    $(document).on('click', function (e) {
        if (!$(e.target).closest('.select').length) {
            $('.select').removeClass('is-active');
        }
    });

    $(".rate-stars").each(function() {
        let rat = $(this).data("rate");
        $(this).rateYo({
            rating: rat,
            starWidth: "12px",
            spacing: "2px",
            readOnly: true,
            normalFill: "#d8d8d8",
            ratedFill: "#0099ff",
            "starSvg": "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"12.875\" height=\"12\" viewBox=\"0 0 12.875 12\">\n" +
                "  <path d=\"M287.565,3614l2.121,3.77,4.313,0.81-3,3.15,0.545,4.27-3.977-1.83-3.976,1.83,0.545-4.27-3-3.15,4.313-.81,2.121-3.77\" transform=\"translate(-281.125 -3614)\"/>\n" +
                "</svg>"
        });
    });

});