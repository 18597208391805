$(function() {

    $(".cat-block-tabs__item").click(function() {
        if ( $(this).closest(".articles-cat-block").length && window.innerWidth >= 1280) {//articles page
            let artBlock = $(this).closest(".articles-cat-block");
            $(".cat-block-tabs__item").removeClass("is-active");
            $(this).addClass("is-active");
            $(".card-article").removeClass("show");
            let dataTab = $(this).attr("data-tab");
            if (dataTab==="all") {
                artBlock.before($(".card-article:not(.inverted)").slice(0,3).addClass("show"));
                artBlock.after($(".card-article:not(.show)").addClass("show"));
            }else{
                if ( $(".card-article[data-cat="+ dataTab +"]").slice(0,1).hasClass("inverted") || $(".card-article[data-cat="+ dataTab +"]").slice(0,2).hasClass("inverted") || $(".card-article[data-cat="+ dataTab +"]").slice(0,3).hasClass("inverted") ) {
                    artBlock.before($(".card-article.inverted[data-cat="+ dataTab +"]").slice(0,1).addClass("show"));
                    artBlock.before($(".card-article[data-cat="+ dataTab +"]:not(.inverted)").slice(0,1).addClass("show"));
                    artBlock.after($(".card-article[data-cat="+ dataTab +"]:not(.show)").addClass("show"));
                }else{
                    artBlock.before($(".card-article[data-cat="+ dataTab +"]").slice(0,3).addClass("show"));
                    artBlock.after($(".card-article[data-cat="+ dataTab +"]").slice(3).addClass("show"));
                }
            }
        }else{//not articles page
            $(".cat-block-tabs__item").removeClass("is-active");
            $(this).addClass("is-active");
            let dataTab = $(this).attr("data-tab");
            if (dataTab==="all") {
                $(".cat-item").addClass("show");
                setTimeout(function() {
                    $(".cat-item.show").each(function() {
                        $(".courses-slider").slick('setPosition');
                    });
                }, 160);
                return;
            }
            $(".cat-item").removeClass("show");
            setTimeout(function() {
                $(".cat-item.show").each(function() {
                    $(".courses-slider").slick('setPosition');
                });
            }, 160);
            $(".cat-item[data-cat="+ dataTab +"]").addClass("show");
        }
    });

    $(".cat-block__more-item").click(function() {
       $(this).toggleClass("is-active");
       if ( $(this).hasClass("is-active") ) {
           $(this).find(".text").text("Меньше категорий");
       }else{
           $(this).find(".text").text("Больше категорий");
       }
       $(this).siblings(".cat-block-tabs__item-a-block").slideToggle();
    });

    if ( window.innerWidth <= 1279 &&  window.innerWidth >= 480 ) {
        $(".cat-block").each(function() {
            if ( $(this).find(".cat-block-tabs__item-a-block .cat-block-tabs__item").length ) {
                let aItems = $(this).find(".cat-block-tabs__item-a-block .cat-block-tabs__item");
                $(this).find(".cat-block-tabs__item-m-block").append(aItems);
            }
        });
    }

});