$(function () {

    let grid = $('.interview-other');
    let itemsGrid = $('.interview-items');
    let items = itemsGrid.find('[data-interview-item]');

    if (!items.length && !grid.length) return;
    let block = $('.interview');
    let slQuote = $('.interview-slider-quote');
    let controlBox = $('.interview-nav .slider-ctrl');
    let mobSliderImg = $('.interview-slider-mob-img');
    let data = [];

    controlBox.append(arrowRight, arrowLeft).on('click', '.default-arrow', function (e) {

        if ( $(this).hasClass('default-arrow--next') ) {
            curIndexA+1 > maxIndex? curIndexA = 0 : curIndexA+=1;
            curIndexN+1 > maxIndex? curIndexN = 0 : curIndexN+=1;
            curIndexP+1 > maxIndex? curIndexP = 0 : curIndexP+=1;
        } else {
            curIndexA-1 < 0? curIndexA = maxIndex : curIndexA-=1;
            curIndexN-1 < 0? curIndexN = maxIndex : curIndexN-=1;
            curIndexP-1 < 0? curIndexP = maxIndex : curIndexP-=1;
        }

        mySliderChange(curIndexA, curIndexN, curIndexP);

    });

    $(document).on('click', '.interview-item-sm', function (e) {
        e.preventDefault();
        if ($(this).data('item') === 'next') {
            curIndexA+1 > maxIndex? curIndexA = 0 : curIndexA+=1;
            curIndexN+1 > maxIndex? curIndexN = 0 : curIndexN+=1;
            curIndexP+1 > maxIndex? curIndexP = 0 : curIndexP+=1;
        } else {
            curIndexA-1 < 0? curIndexA = maxIndex : curIndexA-=1;
            curIndexN-1 < 0? curIndexN = maxIndex : curIndexN-=1;
            curIndexP-1 < 0? curIndexP = maxIndex : curIndexP-=1;
        }

        mySliderChange(curIndexA, curIndexN, curIndexP);
    });

    $(document).on('click', '.interview-nav .slider-dots button', function (e) {
        e.preventDefault();
        let num = $(this).data('slide');
        if ( num === curIndexA ) return;

        num+1 > maxIndex? curIndexN = 0 : curIndexN=num+1;
        num-1 < 0? curIndexP = maxIndex : curIndexP=num-1;
        curIndexA = num;
        mySliderChange(curIndexA, curIndexN, curIndexP);
    });

    $(document).on('click', '.interview-item__link', function(e) {
        e.preventDefault();
        let src = $(this).data('video');
        $.fancybox.open({
            src: src,
        });
    });

    items.each(function (i, el) {
        data.push({
            "text" : $(this).data("text"),
            "imgSm" : $(this).data("img-sm"),
            "imgLg" : $(this).data("img-lg"),
            "quote" : $(this).data("quote"),
            "name" : $(this).data("name"),
            "video" : $(this).data("video")
        });

        controlBox.find('.slider-dots').append(`<li><button class="slider-dot" data-slide='${i}' type="button"></button></li>`);

        $('.interview-slider-mob-img').append(`
            <div class="interview-slider-mob-img__item"><div class="interview-slider-mob-img__item-inner"><img src="${data[i].imgSm}"></div></div>
        `);
        renderInterview(slQuote, data[i]);
    });

    let next = grid.find('[data-item="next"]');
    let prev = grid.find('[data-item="prev"]');

    switch (items.length) {
        case 1:
            next.addClass('hidden');
            prev.addClass('hidden');
            controlBox.closest('.interview-nav').addClass('hidden');
            block.addClass('one-slide');
            break;
        case 2:
            prev.addClass('hidden');
            parseSm(next, data[1]);
            break;
        default:
            parseSm(next, data[1]);
            parseSm(prev, data[items.length-1]);
            break;
    }

    mySliderChange(0, 1, -1, true); //first load

    let maxIndex = items.length-1;
    let curIndexA = 0;
    let curIndexN = 1;
    let curIndexP = maxIndex;

    function mySliderChange(curIndexA, curIndexN, curIndexP, firstRender) {
        if (!firstRender) {
            parseSm(next, data[curIndexN]);
            parseSm(prev, data[curIndexP]);
        }

        items.removeClass('is-active next prev');
        $('.interview-item, .slider-dots li, .interview-slider-mob-img__item').removeClass('is-active next prev');

        $('.interview-photo__img img').attr('src', data[curIndexA].imgLg);
        $('.slider-dots li').eq(curIndexA).addClass('is-active');
        slQuote.find('.interview-item').eq(curIndexA).addClass('is-active');
        slQuote.find('.interview-item').eq(curIndexN).addClass('next');
        slQuote.find('.interview-item').eq(curIndexP).addClass('prev');
        items.eq(curIndexA).addClass('is-active');
        items.eq(curIndexN).addClass('next');
        items.eq(curIndexP).addClass('prev');
        mobSliderImg.find('.interview-slider-mob-img__item').eq(curIndexA).addClass('is-active');
        mobSliderImg.find('.interview-slider-mob-img__item').eq(curIndexN).addClass('next');
        mobSliderImg.find('.interview-slider-mob-img__item').eq(curIndexP).addClass('prev');
    }



    let imgOpt = {
        slidesToShow: 3,
        swipeToSlide: true,
        // variableWidth: true,
        centerMode: true,
        centerPadding: 0,
        focusOnSelect: true,
        infinite: true,
        arrows: false,
        dots: false,
        touchThreshold: 50,
        rows: 0,
        mobileFirst: true,
        asNavFor: slQuote,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            }
        ]
    };
    let quoteOpt = {
        slidesToShow: 1,
        swipeToSlide: true,
        fade: true,
        centerMode: true,
        infinite: true,
        arrows: false,
        dots: false,
        touchThreshold: 50,
        rows: 0,
        adaptiveHeight: true,
        mobileFirst: true,
        asNavFor: mobSliderImg,
        responsive: [
            {
                breakpoint: 768,
                settings: 'unslick'
            }
        ]
    };

    function imgRes() {
        if ( window.innerWidth < 768 ) {
            if ( !mobSliderImg.hasClass("slick-initialized") ) {
                mobSliderImg.slick(imgOpt);
                slQuote.slick(quoteOpt);
            }
        }else{
            if ( mobSliderImg.hasClass("slick-initialized") ) {
                mobSliderImg.slick('unslick');
                slQuote.slick('unslick');
            }
        }
    }

    imgRes();
    $(window).on('resize', function () {
        debounce(imgRes(), 160);
    });

});

function renderInterview(block, data) {

    let card = `
    <div class="interview-item">
        <div class="interview-item__name" data-name="${data.name}">${data.name}</div>
        <div class="interview-item__quote">
            <p class="interview-item__quote-inner" data-before="«" data-after="»">${data.quote}</p>
        </div>
        <a class="interview-item__link" href="javascript:;" data-video="${data.video}">
            <span class="icon">
                <svg width="13" height="17">
                    <use xlink:href="#icon-play"></use>
                </svg>
            </span>
            <span class="text">Посмотреть видео</span>
        </a>
    </div>
    `;

    $(block).append(card);
}

function parseSm(item, data) {
    $(item).find('.img img').attr('src', data.imgSm);
    $(item).find('.text').text(data.text);
}