$(function() {
    let catCompSl = $('.catalog-comp__grid');
    let catCompCtrl = $(".catalog-comp__ctrl-block");
    let catCompOpt = {
        // slidesToShow: 2,
        swipeToSlide: true,
        // variableWidth: true,
        infinite: true,
        nextArrow: arrowRight,
        prevArrow: arrowLeft,
        dots: true,
        appendArrows: catCompCtrl,
        appendDots: catCompCtrl,
        touchThreshold: 50,
        // adaptiveHeight: true,
        mobileFirst: true,
        variableWidth: true,
        // centerMode: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1500,
                settings: 'unslick'
            }
        ]
    };
    function catCompF() {
        if ( window.innerWidth >= 768 && window.innerWidth <= 1500 ) {//слайдер есть
            if ( !catCompSl.hasClass("slick-initialized") ) {
                catCompSl.slick(catCompOpt);
            }
        }else{//слайдера нету
            if ( catCompSl.hasClass("slick-initialized") ) {
                catCompSl.slick('unslick');
            }
        }
    }
    catCompF();
    $(window).on('resize', function () {
        debounce(catCompF(), 160);
    });
    // let catCompSl = new SlickUnslick($('.catalog-comp__grid'), catCompOpt, 1500);

});