$(function() {

    let frSl = $('.franchise-slider'),
        frCtrl = $(".franchise__ctrl-block"),
        franchiseOpt = {
        slidesToShow: 2,
        swipeToSlide: true,
        // variableWidth: true,
        infinite: true,
        nextArrow: arrowRight,
        prevArrow: arrowLeft,
        dots: true,
        appendArrows: frCtrl,
        appendDots: frCtrl,
        touchThreshold: 50,
        // adaptiveHeight: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 640,
                settings: 'unslick'
            }
        ]
    };

    // let franchiseSl = new SlickUnslick($('.franchise-slider'), franchiseOpt, 640);

    function frResF() {
        if ( window.innerWidth >= 640 ) {
            if ( !frSl.hasClass("slick-initialized") ) {
                frSl.slick(franchiseOpt);
            }
        }else{
            if ( frSl.hasClass("slick-initialized") ) {
                frSl.slick('unslick');
            }
        }
    }

    frResF();
    $(window).on('resize', function () {
        debounce(frResF(), 160);
    });

});