$(function() {

    $('.cat-select__main').on('click', function (e) {
        e.preventDefault();
        let select = $(this).closest('.cat-select');
        if (select.hasClass('is-active')) {
            $('.cat-select').removeClass('is-active');
        } else {
            $('.cat-select').removeClass('is-active');
            select.addClass('is-active');
        }
    });

    $('.cat-select__item-link').on('click', function (e) {
        e.preventDefault();
        let select = $(this).closest(".cat-select"),
            catList = select.siblings(".cat-list-block"),
            mainReady = select.find(".cat-select__main-ready");
        select.find(".cat-select__main-init").hide();
        select.find(".cat-select__main-ready").show();
        catList.append("<div class='cat-list-block__item'><span class='cat-list-block__text'>"+ $(this).text() +"</span><span class='cat-list-block__arrow'></span></div>");
        $(this).closest(".cat-select__item").addClass("is-active");
        let count = +mainReady.attr("data-select");
        count++;
        mainReady.attr("data-select", count);
        mainReady.find("span").text(count);
        select.removeClass("is-active");
    });

    $(document).on("click", ".cat-list-block__arrow", function() {
        let item = $(this).closest(".cat-list-block__item"),
            catSelectBlock = $(this).closest(".cat-select-block"),
            selectItems = catSelectBlock.find(".cat-select__item.is-active"),
            mainReady = catSelectBlock.find(".cat-select__main-ready"),
            count = +mainReady.attr("data-select");
        selectItems.each(function() {
            let catLink = $(this).find(".cat-select__item-link");
           if ( catLink.text() === item.find(".cat-list-block__text").text() ) {
               $(this).removeClass("is-active");
           }
        });
        count--;
        mainReady.attr("data-select", count);
        mainReady.find("span").text(count);
        item.remove();
        if ( !$(".cat-list-block__item").length ) {
            catSelectBlock.find(".cat-select__main-init").show();
            catSelectBlock.find(".cat-select__main-ready").hide();
        }
    });

    $(document).on('click', function (e) {
        if (!$(e.target).closest('.cat-select').length) {
            $('.cat-select').removeClass('is-active');
        }
    });

});