$(function() {

    let coursesOpt = {
        slidesToShow: 1,
        swipeToSlide: true,
        // variableWidth: true,
        infinite: true,
        nextArrow: arrowRight,
        prevArrow: arrowLeft,
        dots: false,
        // appendArrows: frCtrl,
        // appendDots: frCtrl,
        touchThreshold: 50,
        // adaptiveHeight: true,
        rows: 0,
        // responsive: [
        //     {
        //         breakpoint: 1199,
        //         settings: {
        //             slidesToShow: 1
        //         }
        //     },
        //     {
        //         breakpoint: 575,
        //         settings: 'unslick'
        //     }
        // ]
    };

    $(".courses-slider").slick(coursesOpt);
    // let franchiseSl = new SlickUnslick($('.franchise-slider'), franchiseOpt, 575);

});