$(function() {

    let contractOpt = {
        slidesToShow: 2,
        swipeToSlide: true,
        // variableWidth: true,
        infinite: true,
        nextArrow: arrowRight,
        prevArrow: arrowLeft,
        dots: false,
        touchThreshold: 50,
        rows: 0,
        mobileFirst: true,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 1280,
                settings: 'unslick'
            }
        ]
    };
    let contractSl = new SlickUnslick($('.sale-contracts__list'), contractOpt, 1280);

});