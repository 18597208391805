$(function () {

    function slBgSetHeight() {
        $('.hero-bg').css({
            'height': window.innerWidth > 991 ? $('.hero').outerHeight() : $('.hero').outerHeight()-360,
        });
    }

    $(window).on('resize', debounce(slBgSetHeight, 160));

    $('.hero-bg-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        fade: true,
        // asNavFor: '.hero-articles-nav-slider',
        asNavFor: '.hero-sl',
    });
    setTimeout(function () {
        slBgSetHeight();
    });

    $('.hero-articles-slider').slick({
        dots: false,
        arrows: false,
        infinite: true,
        fade: true,
        // asNavFor: '.hero-articles-nav-slider',
        asNavFor: '.hero-sl',
    });

    let slNav = $('.hero-articles-nav-tools');
    let speedAutoplay = 8; // скорость автосмены слайда
    $('.hero-articles-nav-slider').find('.line-fill').css({
        'animation-duration': `${speedAutoplay}s`,
    });
    $('.hero-articles-nav-slider').slick({
        rows: 0,
        // slidesToShow: 1,
        focusOnSelect: true,
        swipeToSlide: true,
        variableWidth: true,
        infinite: true,
        nextArrow: arrowRight,
        prevArrow: arrowLeft,
        dots: true,
        touchThreshold: 50,
        adaptiveHeight: true,
        appendDots: slNav,
        appendArrows: slNav,
        autoplay: true,
        autoplaySpeed: speedAutoplay*1000,
        pauseOnHover: false,
        pauseOnFocus: false,
        // asNavFor: '.hero-articles-slider',
        asNavFor: '.hero-sl',
    });

    $('.last-news-slider').slick({
        rows: 0,
        // slidesToShow: 1,
        swipeToSlide: true,
        variableWidth: true,
        infinite: true,
        dots: false,
        arrows: false,
        touchThreshold: 50,
        adaptiveHeight: true,
        // autoplay: true,
        // autoplaySpeed: 8000,
    });

});