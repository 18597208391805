$(function() {

    let rentCtrl = $(".rent__ctrl-block");
    let rentOpt = {
        // slidesToShow: 2,
        swipeToSlide: true,
        // variableWidth: true,
        infinite: true,
        nextArrow: arrowRight,
        prevArrow: arrowLeft,
        dots: true,
        appendArrows: rentCtrl,
        appendDots: rentCtrl,
        touchThreshold: 50,
        // adaptiveHeight: true,
        mobileFirst: true,
        variableWidth: true,
        // centerMode: true,
        rows: 0,
        responsive: [
            {
                breakpoint: 0,
                settings: {
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1280,
                settings: 'unslick'
            }
        ]
    };
    let rentSl = new SlickUnslick($('.rent__grid'), rentOpt, 1280);

});