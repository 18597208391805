$(function() {

    $(document).on('click', '.sorting-select', function (e) {
        $(this).closest('.sorting-block').toggleClass('is-active');
    });
    $(document).on('click', '.sorting-select__list a', function (e) {
        e.preventDefault();
        let city = $(this).text();
        $('.sorting-block').removeClass('is-active');
        $('.sorting-block').removeClass('is-active').find('.sorting-select .text').text(city);
    });

    $(document).on('click', function (e) {
        if (!$(e.target).closest('.sorting-block').length) {
            $('.sorting-block').removeClass('is-active');
        }
    });

});
